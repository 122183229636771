var vh = document.documentElement.clientHeight * 0.01;
document.documentElement.style.setProperty('--vh', vh+'px');
        
var isRTL = ($('html').attr('dir') == "rtl") ? true : false,
    winWidth = $(window).width(),
    winHeight = $(window).height(),
    boardIndex = 0;

$(function() {
    browserDetect();
});

$(document).ready(function(){
    $('body').css({"overflow" : "hidden"});
    if($(".loaderscreen").length > 0){
    } else {
        setTimeout(function(){
            $('body').css({"overflow" : "auto"});
        }, 3000);
    }

    $('#profileCarousel').on('show.bs.modal', function () {
        if(!$('body').hasClass('profile-poup-first-show')) {
            $(".js-profile-list").owlCarousel({
                items:1,
                nav: true,
                loop: true,
                startPosition: boardIndex,
                onInitialized: function() {
                    $(".js-profile-list .owl-nav .owl-prev").after('<span class="nav-txt">more profiles</span>');
                    setTimeout(function(){
                        $('.js-profile-list').addClass('this-initialized');
                    }, 600);
                }
            });
        } else {
            setTimeout(function(){
                $('.js-profile-list').addClass('this-initialized');
            }, 600);
        }
        $('body').addClass('profile-poup-first-show');
    });
    $('#profileCarousel').on('hidden.bs.modal', function () {
        $('.js-profile-list').removeClass('this-initialized');
    });
});

$(window).on('load', function() {
    // Do after Page ready
});
$(function(){
    setTimeout(function(){
        loadLazyImgs();
    }, 1000);
});

function loadLazyImgs() {
    $('[data-bgimg]').each(function(){
        var thisImgUrl = $(this).attr('data-bgimg');
        $(this).css('background-image', thisImgUrl);
    });
    $('[data-img-src]').each(function(){
        var thisSrc = $(this).attr('data-img-src');
        var thisAttr = $(this).attr('data-alt');
        $(this).after('<img src="'+thisSrc+'" alt="'+thisAttr+'">');
        $(this).remove();
    });
}

$(window).on('resize orientationchange', function() {
    // Do on resize
    winWidth = $(window).width(),
    winHeight = $(window).height();
    dataSrc();
});

$(window).on('scroll', function() {
    //Do on Scroll
    stickyHeader();
});

$(document).keyup(function(e) {
    if (e.keyCode == 27) {
        menuCloseOnESC();
    }
});

function doOnLoad() {
    //OnLoad Function
    setTimeout(function() {
        addVideoPlugin();
        // addParallexPlugin()
    }, 10000);
    detectScroll();
    ChangeToSvg();
    jsHamburgerMenuBtn();
    mCustomScroll();
    keywordSlider();
    customSelect('.custom-select');
    owlSlider();
    setNavigation();
    formValidation ();
    dataSrc();
}

function browserDetect() {
    navigator.sayswho = (function() {
        var ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();
    $('body').addClass(navigator.sayswho);
}

function addVideoPlugin() {
    if (winWidth > 1024 && $('.js-video').get(0)) {
        var plyrScriptElement = document.createElement("script");
        if (isRTL) {
            plyrScriptElement.setAttribute('src', customVariables.baseURL + '../assets/js/plyr.min.js');
        } else {
            plyrScriptElement.setAttribute('src', customVariables.baseURL + 'assets/js/plyr.min.js');
        }
        plyrScriptElement.setAttribute('async', 'true');
        document.body.appendChild(plyrScriptElement);
    } else {
        jsVideoDirect();
    }
}

var players = [];

function jsVideo() {
    // Custom player
    if ($('.js-video').length) {
        $('.js-video').each(function(i) {
            var thisParent = $(this).parent();
            players[i] = new Plyr(this, {
                playsinline: true,
            });
            thisParent.find('.plyr').attr('data-video-instance', i);
        });
    }
}

function jsVideoDirect() {
    if ($('.js-video').length) {
        $('.js-video').each(function(i) {
            $(this).attr('data-video-instance', i);
            var videoId = $(this).attr('data-plyr-embed-id');
            $(this).html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + videoId + '?rel=0&playsinline=1&enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
        });
    }
}

var menuAnimation =  gsap.timeline({
                        onComplete: function(){
                            $('body').addClass("menu-has-open");
                        },
                        onReverseComplete: function(){
                            $('body').removeClass("menu-has-open");
                        }
                    })
                    .fromTo('.menu-ripple.ripple--1',{ scale: 0}, { scale: 3, duration: .75, ease: Power1.easeIn,
                        // onComplete: function(){
                        //     $('.menu').show();
                        //     $('.hamburg-btn').addClass("open");
                        // },
                        // onReverseComplete: function(){
                        //     $('.menu').hide();
                        //     $('.hamburg-btn').removeClass("open");
                        // }
                    })
                    .add(function(){
                        $('.menu').hide();
                        $('.hamburg-btn').removeClass("open");
                        $('.menu-btn .txt-menu').css('opacity', 1);
                        $('.menu-btn .txt-close').css('opacity', 0);
                    }, '-=.6')
                    .add(function(){
                        $('.menu').show();
                        $('.hamburg-btn').addClass("open");
                        $('.menu-btn .txt-menu').css('opacity', 0);
                        $('.menu-btn .txt-close').css('opacity', 1);
                    }, '-=.5')
                    .fromTo('.menu-ripple.ripple--2',{ scale: 0}, { scale: 3, duration: .75, ease: Power1.easeIn}, "-=.5", )
                    .add(function(){
                        $('body').removeClass("menu-opening");
                    }, '-=.25')
                    .add(function(){
                        $('body').addClass("menu-opening");
                    }, '-=.25')
                    .fromTo('.menu',{ opacity: 0}, { opacity: 1, duration: .15, ease: Power1.easeIn});
menuAnimation.pause();
function jsHamburgerMenuBtn() {
    $(".menu-btn").click(function(e) {
        if ($('body').hasClass('menu-has-open')) {
            enableScroll();
            menuAnimation.reverse();
        } else {
            menuAnimation.play();
            disableScroll();
        }
    });
}

function menuCloseOnESC() {
    if ($('body').hasClass('menu-has-open')) {
        $(".menu-btn").trigger("click");
    }
}

function mCustomScroll() {
    $('.keyword-slider-items').mCustomScrollbar({ 
        theme:"dark-3"
    });
}

var keywordCurrentIndex = 0;

function keywordSlider() {
    var getWindowHeight = $(window).height();
    var getGrandParent = $('.keyword-slider');
    var getParent = $('.keyword-slider-items');
    $(getGrandParent).css({"height": getWindowHeight - 60});
    $(getParent).find('li').find('span').hover(function(){
        var getCurrentActive = $(this).parent().index();
        $(this).parent().siblings().removeClass('active');
        $(this).parent().addClass('active');
        $(getGrandParent).find('.keyword-slider-img').removeClass('active');
        $(getGrandParent).find('.keyword-slider-img').eq(getCurrentActive).addClass('active');
    });

    if(winWidth < 1025) {
        setInterval(function(){
            var getGrandParent = $('.keyword-slider');
            $('.keyword-slider-items li.active').removeClass('active');
            $('.keyword-slider-items li').eq(keywordCurrentIndex).addClass('active');
            $(getGrandParent).find('.keyword-slider-img.active').removeClass('active');
            $(getGrandParent).find('.keyword-slider-img').eq(keywordCurrentIndex).addClass('active');

            keywordCurrentIndex +=1;
            if(keywordCurrentIndex > $('.keyword-slider-items li').length-1) {
                keywordCurrentIndex = 0;
            }
        }, 4000);
    }
}

function customSelect(target) {
    var target = $(target);
    target.each(function (i, e) {
        var tarPlace = $(e).data('placeholder');
        var tarPar = $(e).parent('.select2-box');
        $(e).select2({
            minimumResultsForSearch: Infinity,
            placeholder: tarPlace,
            dropdownParent: tarPar,
        });
    });
}

function detectScroll() {
    const header = $('body');
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;

    $(window).on("scroll", function(){
        const currentScroll = $(this).scrollTop();
        if(currentScroll == 0 && $(header).hasClass(scrollDown)){
            $(header).removeClass(scrollDown);
            return;
        }
        if(currentScroll > lastScroll && !$(header).hasClass(scrollDown)) {
            $(header).removeClass(scrollUp);
            $(header).addClass(scrollDown);
        } else if (currentScroll < lastScroll && $(header).hasClass(scrollDown)) {
            $(header).removeClass(scrollDown);
            $(header).addClass(scrollUp);
        }
        lastScroll = currentScroll;
    });

    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        $(window).bind('mousewheel', function(e) {
            if (e.originalEvent.wheelDelta / 120 > 0) {
                if ($(window).scrollTop() < 20) return false;
            } 
        });
    }
}

function stickyHeader() {
    const getHeader = $('header');
    const getHeaderHeight = getHeader.height();
    if ($(window).scrollTop() > getHeaderHeight) {
        getHeader.addClass('fixed');
    } else {
        getHeader.removeClass('fixed');
    }
}

function disableScroll() { 
    // Get the current page scroll position 
    scrollTop =  
    window.pageYOffset || document.documentElement.scrollTop; 
    scrollLeft =  
    window.pageXOffset || document.documentElement.scrollLeft, 
    // if any scroll is attempted, 
    // set this to the previous value 
    window.onscroll = function() { 
        window.scrollTo(scrollLeft, scrollTop); 
    }; 
} 

function owlSlider() {
    $('.board-of-directors .item').each(function(i){
        $(this).attr('data-index', i);
    });
    $('.bod-slider').owlCarousel({
        margin:20,
        loop: true,
        dots: false,
        navText: ["<i class='owl-nav-left'></i>","<i class='owl-nav-right'></i>"],
        responsive:{
            0:{
                items:1,
                center: true,
                autoWidth: true
            },
            600:{
                items:3
            },
            1000:{
                items:4,
                nav: true,
                pagination :true
            }
        },
        onInitialized: function() {
            if($('.board-of-directors .item').get(0)) {
                $('.board-of-directors .item').click(function(){
                    boardIndex = parseInt($(this).attr('data-index'));
                    if($('body').hasClass('profile-poup-first-show')) {
                        $(".js-profile-list").trigger('to.owl.carousel', [boardIndex, 0]);
                    }
                });
            }
        }
    })

    if ($(window).width() < 767) {
        $('.gallery').addClass("owl-carousel");
        $('.gallery').owlCarousel({
            loop:false,    
            margin: 25,
            autoWidth:true,  
            nav: true,
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            dots: true
        });
    } else{
        $('.gallery').owlCarousel('destroy');
        $('.gallery').removeClass("owl-carousel");
    }
}

function setNavigation() {
    $('header .menu ul li a').removeClass('active');
    var url = window.location.href;
    url = url.substring(0, (url.indexOf("#") == -1) ? url.length : url.indexOf("#"));
    url = url.substring(0, (url.indexOf("?") == -1) ? url.length : url.indexOf("?"));
    url = url.substr(url.lastIndexOf("/") + 1);
    if(url == ''){
        url = 'index.html';
    }
    $('header .menu ul li').each(function(){
        var href = $(this).find('a').attr('href');
        if(url == href){
            $(this).siblings().find('a').removeClass('active');
            $(this).find('a').addClass('active');
        }
    });
}

function formValidation () {
    $("#formValidate").validate();
}

function dataSrc() {
    var videoSrc;
    if (winWidth < 992) {
        $('[data-mobile-vsrc]').each(function () {
            var $ths = $(this);
            videoSrc = $ths.attr('data-mobile-vsrc');
            $ths.attr('src',videoSrc);
        });
    } else {
        $('[data-desktop-vsrc]').each(function () {
            var $ths = $(this);
            videoSrc = $ths.attr('data-desktop-vsrc');
            $ths.attr('src',videoSrc);
        });
    }
}
function afterAnimStuff() {
    VanillaTilt.init(document.querySelectorAll(".parallex-img"), {
        max: 25,
        speed: 400,
        reset: true
    });

    $('.parallex-img').addClass('set-text-align');

    $('.parallex-img').hover(function(){
       if($(this).hasClass('set-text-align')){
           $(this).removeClass('set-text-align');
       }else{
           $(this).addClass('set-text-align');
       }
    });

    doOnLoad();
}